import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
//import * as styles from "../styles/components/_email-list-form.scss"

export default function Imprint() {
  return (
    <Layout>
      <SEO title="Metric Soup" />
      <div className="EmailListForm">
        <h1>Legal Disclosure/Impressum</h1>
        <p className="styles.EmailListForm.Text">
          <br />
          Information provided according to Sec. 5 German Telemedia Act (TMG){" "}
          <br />
          <br />
          www.metricsoup.app is developed and run by <br />
          H Janse van Rensburg <br />
          Cecilienstraße 195 <br />
          12683 <br />
          Berlin <br />
          Germany <br />
          <br />
          Email: <a href="mailto:info@metricsoup.app">
            info@metricsoup.app
          </a>{" "}
          Website: <a href="https://www.metricsoup.app">www.metricsoup.app</a>
          <br />
          <br />
        </p>
        <h2>Dispute resolution </h2>
        <p className="styles.EmailListForm.Text">
          <br />
          The European Online Dispute Resolution (ODR) platform is provided by
          the European Commission to make online shopping safer and fairer
          through access to quality dispute resolution tools:{" "}
          <a href="https://www.ec.europa.eu/consumers/odr">
            https://www.ec.europa.eu/consumers/odr
          </a>
          .<br />
          <br />
          Please find our email in the impressum/legal notice. <br />
          <br />
          We do not take part in online dispute resolutions at consumer
          arbitration boards.
          <br />
          <br />
        </p>
        <h2>Disclaimer</h2>
        <p className="styles.EmailListForm.Text">
          <br />
          Accountability for content. The contents of our pages have been
          created with the utmost care. However, we cannot guarantee the
          contents' accuracy, completeness or topicality. According to statutory
          provisions, we are furthermore responsible for our own content on
          these web pages. In this context, please note that we are accordingly
          not obliged to monitor merely the transmitted or saved information of
          third parties, or investigate circumstances pointing to illegal
          activity. Our obligations to remove or block the use of information
          under generally applicable laws remain unaffected by this as per §§ 8
          to 10 of the Telemedia Act (TMG).
          <br />
          <br />
        </p>
        <h2>Accountability for links</h2>
        <p className="styles.EmailListForm.Text">
          <br />
          Responsibility for the content of external links (to web pages of
          third parties) lies solely with the operators of the linked pages. No
          violations were evident to us at the time of linking. Should any legal
          infringement become known to us, we will remove the respective link
          immediately.
          <br />
          <br />
        </p>
        <h2>Copyright</h2>
        <p className="styles.EmailListForm.Text">
          <br />
          Our web pages and their contents are subject to German copyright law.
          Unless expressly permitted by law (§ 44a et seq. of the copyright
          law), every form of utilizing, reproducing or processing works subject
          to copyright protection on our web pages requires the prior consent of
          the respective owner of the rights. Individual reproductions of a work
          are allowed only for private use, so must not serve either directly or
          indirectly for earnings. Unauthorized utilization of copyrighted works
          is punishable (§ 106 of the copyright law).
          <br />
        </p>
      </div>
    </Layout>
  )
}
